body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-borderless tbody+tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0 !important;
}

#column-undefined, #column-undefined div{
  width: 100%;
}

.font-weight-bold {
  font-weight: 649!important;
}

.cell-total{
  width: 100% !important;
    /* text-align: right; */
  display: flex;
  align-content: end;
  white-space: nowrap;
  justify-content: flex-end;
  overflow: unset !important;
}

.rdt_TableHeadRow{
  pointer-events: auto !important;
}

.btn-icon{
  min-width: 0px !important;
  border: 0 !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(70vh - 80px) !important;
}

@media (min-width: 992px) {
  .modal-xl {
    width: 1000px;
  }
}

@media (max-width: 768px) {
.m-none-small {
	margin: 0 !important;
}

.m-auto-small {
	margin: 0 auto !important;
}

.m-xs-small {
	margin: 5px !important;
}

.m-sm-small {
	margin: 10px !important;
}

.m-md-small {
	margin: 15px !important;
}

.m-lg-small {
	margin: 20px !important;
}

.m-xl-small {
	margin: 25px !important;
}

.m-xlg-small {
	margin: 30px !important;
}

/* spacement top	*/
.mt-none-small {
	margin-top: 0 !important;
}

.mt-xs-small {
	margin-top: 5px !important;
}

.mt-sm-small {
	margin-top: 10px !important;
}

.mt-md-small {
	margin-top: 15px !important;
}

.mt-lg-small {
	margin-top: 20px !important;
}

.mt-xl-small {
	margin-top: 25px !important;
}

.mt-xlg-small {
	margin-top: 30px !important;
}

/* spacement bottom	*/
.mb-none-small {
	margin-bottom: 0 !important;
}

.mb-xs-small {
	margin-bottom: 5px !important;
}

.mb-sm-small {
	margin-bottom: 10px !important;
}

.mb-md-small {
	margin-bottom: 15px !important;
}

.mb-lg-small {
	margin-bottom: 20px !important;
}

.mb-xl-small {
	margin-bottom: 25px !important;
}

.mb-xlg-small {
	margin-bottom: 30px !important;
}

/* spacement left	*/
.mr-none-small {
	margin-left: 0 !important;
}

.ml-xs-small {
	margin-left: 5px !important;
}

.ml-sm-small {
	margin-left: 10px !important;
}

.ml-md-small {
	margin-left: 15px !important;
}

.ml-lg-small {
	margin-left: 20px !important;
}

.ml-xl-small {
	margin-left: 25px !important;
}

.ml-xlg-small {
	margin-left: 30px !important;
}

/* spacement right	*/
.mr-none-small {
	margin-right: 0 !important;
}

.mr-xs-small {
	margin-right: 5px !important;
}

.mr-sm-small {
	margin-right: 10px !important;
}

.mr-md-small {
	margin-right: 15px !important;
}

.mr-lg-small {
	margin-right: 20px !important;
}

.mr-xl-small {
	margin-right: 25px !important;
}

.mr-xlg-small {
	margin-right: 30px !important;
}

/* Spacement Padding */
.p-none-small {
	padding: 0 !important;
}

.p-xs-small {
	padding: 5px !important;
}

.p-sm-small {
	padding: 10px !important;
}

.p-md-small {
	padding: 15px !important;
}

.p-lg-small {
	padding: 20px !important;
}

.p-xl-small {
	padding: 25px !important;
}

.p-xlg-small {
	padding: 30px !important;
}

/* spacement top	*/
.pt-none-small {
	padding-top: 0 !important;
}

.pt-xs-small {
	padding-top: 5px !important;
}

.pt-sm-small {
	padding-top: 10px !important;
}

.pt-md-small {
	padding-top: 15px !important;
}

.pt-lg-small {
	padding-top: 20px !important;
}

.pt-xl-small {
	padding-top: 25px !important;
}

.pt-xlg-small {
	padding-top: 30px !important;
}

/* spacement bottom	*/
.pb-none-small {
	padding-bottom: 0 !important;
}

.pb-xs-small {
	padding-bottom: 5px !important;
}

.pb-sm-small {
	padding-bottom: 10px !important;
}

.pb-md-small {
	padding-bottom: 15px !important;
}

.pb-lg-small {
	padding-bottom: 20px !important;
}

.pb-xl-small {
	padding-bottom: 25px !important;
}

.pb-xlg-small {
	padding-bottom: 30px !important;
}

/* spacement left	*/
.pr-none-small {
	padding-left: 0 !important;
}

.pl-xs-small {
	padding-left: 5px !important;
}

.pl-sm-small {
	padding-left: 10px !important;
}

.pl-md-small {
	padding-left: 15px !important;
}

.pl-lg-small {
	padding-left: 20px !important;
}

.pl-xl-small {
	padding-left: 25px !important;
}

.pl-xlg-small {
	padding-left: 30px !important;
}

/* spacement right	*/
.pr-none-small {
	padding-right: 0 !important;
}

.pr-xs-small {
	padding-right: 5px !important;
}

.pr-sm-small {
	padding-right: 10px !important;
}

.pr-md-small {
	padding-right: 15px !important;
}

.pr-lg-small {
	padding-right: 20px !important;
}

.pr-xl-small {
	padding-right: 25px !important;
}

.pr-xlg-small {
	padding-right: 30px !important;
}
}

@media (min-width: 768px) {
  

.m-none-medium {
	margin: 0 !important;
}

.m-auto-medium {
	margin: 0 auto !important;
}

.m-xs-medium {
	margin: 5px !important;
}

.m-sm-medium {
	margin: 10px !important;
}

.m-md-medium {
	margin: 15px !important;
}

.m-lg-medium {
	margin: 20px !important;
}

.m-xl-medium {
	margin: 25px !important;
}

.m-xlg-medium {
	margin: 30px !important;
}

/* spacement top	*/
.mt-none-medium {
	margin-top: 0 !important;
}

.mt-xs-medium {
	margin-top: 5px !important;
}

.mt-sm-medium {
	margin-top: 10px !important;
}

.mt-md-medium {
	margin-top: 15px !important;
}

.mt-lg-medium {
	margin-top: 20px !important;
}

.mt-xl-medium {
	margin-top: 25px !important;
}

.mt-xlg-medium {
	margin-top: 30px !important;
}

/* spacement bottom	*/
.mb-none-medium {
	margin-bottom: 0 !important;
}

.mb-xs-medium {
	margin-bottom: 5px !important;
}

.mb-sm-medium {
	margin-bottom: 10px !important;
}

.mb-md-medium {
	margin-bottom: 15px !important;
}

.mb-lg-medium {
	margin-bottom: 20px !important;
}

.mb-xl-medium {
	margin-bottom: 25px !important;
}

.mb-xlg-medium {
	margin-bottom: 30px !important;
}

/* spacement left	*/
.mr-none-medium {
	margin-left: 0 !important;
}

.ml-xs-medium {
	margin-left: 5px !important;
}

.ml-sm-medium {
	margin-left: 10px !important;
}

.ml-md-medium {
	margin-left: 15px !important;
}

.ml-lg-medium {
	margin-left: 20px !important;
}

.ml-xl-medium {
	margin-left: 25px !important;
}

.ml-xlg-medium {
	margin-left: 30px !important;
}

/* spacement right	*/
.mr-none-medium {
	margin-right: 0 !important;
}

.mr-xs-medium {
	margin-right: 5px !important;
}

.mr-sm-medium {
	margin-right: 10px !important;
}

.mr-md-medium {
	margin-right: 15px !important;
}

.mr-lg-medium {
	margin-right: 20px !important;
}

.mr-xl-medium {
	margin-right: 25px !important;
}

.mr-xlg-medium {
	margin-right: 30px !important;
}

/* Spacement Padding */
.p-none-medium {
	padding: 0 !important;
}

.p-xs-medium {
	padding: 5px !important;
}

.p-sm-medium {
	padding: 10px !important;
}

.p-md-medium {
	padding: 15px !important;
}

.p-lg-medium {
	padding: 20px !important;
}

.p-xl-medium {
	padding: 25px !important;
}

.p-xlg-medium {
	padding: 30px !important;
}

/* spacement top	*/
.pt-none-medium {
	padding-top: 0 !important;
}

.pt-xs-medium {
	padding-top: 5px !important;
}

.pt-sm-medium {
	padding-top: 10px !important;
}

.pt-md-medium {
	padding-top: 15px !important;
}

.pt-lg-medium {
	padding-top: 20px !important;
}

.pt-xl-medium {
	padding-top: 25px !important;
}

.pt-xlg-medium {
	padding-top: 30px !important;
}

/* spacement bottom	*/
.pb-none-medium {
	padding-bottom: 0 !important;
}

.pb-xs-medium {
	padding-bottom: 5px !important;
}

.pb-sm-medium {
	padding-bottom: 10px !important;
}

.pb-md-medium {
	padding-bottom: 15px !important;
}

.pb-lg-medium {
	padding-bottom: 20px !important;
}

.pb-xl-medium {
	padding-bottom: 25px !important;
}

.pb-xlg-medium {
	padding-bottom: 30px !important;
}

/* spacement left	*/
.pr-none-medium {
	padding-left: 0 !important;
}

.pl-xs-medium {
	padding-left: 5px !important;
}

.pl-sm-medium {
	padding-left: 10px !important;
}

.pl-md-medium {
	padding-left: 15px !important;
}

.pl-lg-medium {
	padding-left: 20px !important;
}

.pl-xl-medium {
	padding-left: 25px !important;
}

.pl-xlg-medium {
	padding-left: 30px !important;
}

/* spacement right	*/
.pr-none-medium {
	padding-right: 0 !important;
}

.pr-xs-medium {
	padding-right: 5px !important;
}

.pr-sm-medium {
	padding-right: 10px !important;
}

.pr-md-medium {
	padding-right: 15px !important;
}

.pr-lg-medium {
	padding-right: 20px !important;
}

.pr-xl-medium {
	padding-right: 25px !important;
}

.pr-xlg-medium {
	padding-right: 30px !important;
}
}

@media (min-width: 992px) {


  .m-none-large {
    margin: 0 !important;
  }
  
  .m-auto-large {
    margin: 0 auto !important;
  }
  
  .m-xs-large {
    margin: 5px !important;
  }
  
  .m-sm-large {
    margin: 10px !important;
  }
  
  .m-md-large {
    margin: 15px !important;
  }
  
  .m-lg-large {
    margin: 20px !important;
  }
  
  .m-xl-large {
    margin: 25px !important;
  }
  
  .m-xlg-large {
    margin: 30px !important;
  }
  
  /* spacement top	*/
  .mt-none-large {
    margin-top: 0 !important;
  }
  
  .mt-xs-large {
    margin-top: 5px !important;
  }
  
  .mt-sm-large {
    margin-top: 10px !important;
  }
  
  .mt-md-large {
    margin-top: 15px !important;
  }
  
  .mt-lg-large {
    margin-top: 20px !important;
  }
  
  .mt-xl-large {
    margin-top: 25px !important;
  }
  
  .mt-xlg-large {
    margin-top: 30px !important;
  }
  
  /* spacement bottom	*/
  .mb-none-large {
    margin-bottom: 0 !important;
  }
  
  .mb-xs-large {
    margin-bottom: 5px !important;
  }
  
  .mb-sm-large {
    margin-bottom: 10px !important;
  }
  
  .mb-md-large {
    margin-bottom: 15px !important;
  }
  
  .mb-lg-large {
    margin-bottom: 20px !important;
  }
  
  .mb-xl-large {
    margin-bottom: 25px !important;
  }
  
  .mb-xlg-large {
    margin-bottom: 30px !important;
  }
  
  /* spacement left	*/
  .mr-none-large {
    margin-left: 0 !important;
  }
  
  .ml-xs-large {
    margin-left: 5px !important;
  }
  
  .ml-sm-large {
    margin-left: 10px !important;
  }
  
  .ml-md-large {
    margin-left: 15px !important;
  }
  
  .ml-lg-large {
    margin-left: 20px !important;
  }
  
  .ml-xl-large {
    margin-left: 25px !important;
  }
  
  .ml-xlg-large {
    margin-left: 30px !important;
  }
  
  /* spacement right	*/
  .mr-none-large {
    margin-right: 0 !important;
  }
  
  .mr-xs-large {
    margin-right: 5px !important;
  }
  
  .mr-sm-large {
    margin-right: 10px !important;
  }
  
  .mr-md-large {
    margin-right: 15px !important;
  }
  
  .mr-lg-large {
    margin-right: 20px !important;
  }
  
  .mr-xl-large {
    margin-right: 25px !important;
  }
  
  .mr-xlg-large {
    margin-right: 30px !important;
  }
  
  /* Spacement Padding */
  .p-none-large {
    padding: 0 !important;
  }
  
  .p-xs-large {
    padding: 5px !important;
  }
  
  .p-sm-large {
    padding: 10px !important;
  }
  
  .p-md-large {
    padding: 15px !important;
  }
  
  .p-lg-large {
    padding: 20px !important;
  }
  
  .p-xl-large {
    padding: 25px !important;
  }
  
  .p-xlg-large {
    padding: 30px !important;
  }
  
  /* spacement top	*/
  .pt-none-large {
    padding-top: 0 !important;
  }
  
  .pt-xs-large {
    padding-top: 5px !important;
  }
  
  .pt-sm-large {
    padding-top: 10px !important;
  }
  
  .pt-md-large {
    padding-top: 15px !important;
  }
  
  .pt-lg-large {
    padding-top: 20px !important;
  }
  
  .pt-xl-large {
    padding-top: 25px !important;
  }
  
  .pt-xlg-large {
    padding-top: 30px !important;
  }
  
  /* spacement bottom	*/
  .pb-none-large {
    padding-bottom: 0 !important;
  }
  
  .pb-xs-large {
    padding-bottom: 5px !important;
  }
  
  .pb-sm-large {
    padding-bottom: 10px !important;
  }
  
  .pb-md-large {
    padding-bottom: 15px !important;
  }
  
  .pb-lg-large {
    padding-bottom: 20px !important;
  }
  
  .pb-xl-large {
    padding-bottom: 25px !important;
  }
  
  .pb-xlg-large {
    padding-bottom: 30px !important;
  }
  
  /* spacement left	*/
  .pr-none-large {
    padding-left: 0 !important;
  }
  
  .pl-xs-large {
    padding-left: 5px !important;
  }
  
  .pl-sm-large {
    padding-left: 10px !important;
  }
  
  .pl-md-large {
    padding-left: 15px !important;
  }
  
  .pl-lg-large {
    padding-left: 20px !important;
  }
  
  .pl-xl-large {
    padding-left: 25px !important;
  }
  
  .pl-xlg-large {
    padding-left: 30px !important;
  }
  
  /* spacement right	*/
  .pr-none-large {
    padding-right: 0 !important;
  }
  
  .pr-xs-large {
    padding-right: 5px !important;
  }
  
  .pr-sm-large {
    padding-right: 10px !important;
  }
  
  .pr-md-large {
    padding-right: 15px !important;
  }
  
  .pr-lg-large {
    padding-right: 20px !important;
  }
  
  .pr-xl-large {
    padding-right: 25px !important;
  }
  
  .pr-xlg-large {
    padding-right: 30px !important;
  }
}

@media (min-width: 1200px) {


  .m-none-xlarge {
    margin: 0 !important;
  }
  
  .m-auto-xlarge {
    margin: 0 auto !important;
  }
  
  .m-xs-xlarge {
    margin: 5px !important;
  }
  
  .m-sm-xlarge {
    margin: 10px !important;
  }
  
  .m-md-xlarge {
    margin: 15px !important;
  }
  
  .m-lg-xlarge {
    margin: 20px !important;
  }
  
  .m-xl-xlarge {
    margin: 25px !important;
  }
  
  .m-xlg-xlarge {
    margin: 30px !important;
  }
  
  /* spacement top	*/
  .mt-none-xlarge {
    margin-top: 0 !important;
  }
  
  .mt-xs-xlarge {
    margin-top: 5px !important;
  }
  
  .mt-sm-xlarge {
    margin-top: 10px !important;
  }
  
  .mt-md-xlarge {
    margin-top: 15px !important;
  }
  
  .mt-lg-xlarge {
    margin-top: 20px !important;
  }
  
  .mt-xl-xlarge {
    margin-top: 25px !important;
  }
  
  .mt-xlg-xlarge {
    margin-top: 30px !important;
  }
  
  /* spacement bottom	*/
  .mb-none-xlarge {
    margin-bottom: 0 !important;
  }
  
  .mb-xs-xlarge {
    margin-bottom: 5px !important;
  }
  
  .mb-sm-xlarge {
    margin-bottom: 10px !important;
  }
  
  .mb-md-xlarge {
    margin-bottom: 15px !important;
  }
  
  .mb-lg-xlarge {
    margin-bottom: 20px !important;
  }
  
  .mb-xl-xlarge {
    margin-bottom: 25px !important;
  }
  
  .mb-xlg-xlarge {
    margin-bottom: 30px !important;
  }
  
  /* spacement left	*/
  .mr-none-xlarge {
    margin-left: 0 !important;
  }
  
  .ml-xs-xlarge {
    margin-left: 5px !important;
  }
  
  .ml-sm-xlarge {
    margin-left: 10px !important;
  }
  
  .ml-md-xlarge {
    margin-left: 15px !important;
  }
  
  .ml-lg-xlarge {
    margin-left: 20px !important;
  }
  
  .ml-xl-xlarge {
    margin-left: 25px !important;
  }
  
  .ml-xlg-xlarge {
    margin-left: 30px !important;
  }
  
  /* spacement right	*/
  .mr-none-xlarge {
    margin-right: 0 !important;
  }
  
  .mr-xs-xlarge {
    margin-right: 5px !important;
  }
  
  .mr-sm-xlarge {
    margin-right: 10px !important;
  }
  
  .mr-md-xlarge {
    margin-right: 15px !important;
  }
  
  .mr-lg-xlarge {
    margin-right: 20px !important;
  }
  
  .mr-xl-xlarge {
    margin-right: 25px !important;
  }
  
  .mr-xlg-xlarge {
    margin-right: 30px !important;
  }
  
  /* Spacement Padding */
  .p-none-xlarge {
    padding: 0 !important;
  }
  
  .p-xs-xlarge {
    padding: 5px !important;
  }
  
  .p-sm-xlarge {
    padding: 10px !important;
  }
  
  .p-md-xlarge {
    padding: 15px !important;
  }
  
  .p-lg-xlarge {
    padding: 20px !important;
  }
  
  .p-xl-xlarge {
    padding: 25px !important;
  }
  
  .p-xlg-xlarge {
    padding: 30px !important;
  }
  
  /* spacement top	*/
  .pt-none-xlarge {
    padding-top: 0 !important;
  }
  
  .pt-xs-xlarge {
    padding-top: 5px !important;
  }
  
  .pt-sm-xlarge {
    padding-top: 10px !important;
  }
  
  .pt-md-xlarge {
    padding-top: 15px !important;
  }
  
  .pt-lg-xlarge {
    padding-top: 20px !important;
  }
  
  .pt-xl-xlarge {
    padding-top: 25px !important;
  }
  
  .pt-xlg-xlarge {
    padding-top: 30px !important;
  }
  
  /* spacement bottom	*/
  .pb-none-xlarge {
    padding-bottom: 0 !important;
  }
  
  .pb-xs-xlarge {
    padding-bottom: 5px !important;
  }
  
  .pb-sm-xlarge {
    padding-bottom: 10px !important;
  }
  
  .pb-md-xlarge {
    padding-bottom: 15px !important;
  }
  
  .pb-lg-xlarge {
    padding-bottom: 20px !important;
  }
  
  .pb-xl-xlarge {
    padding-bottom: 25px !important;
  }
  
  .pb-xlg-xlarge {
    padding-bottom: 30px !important;
  }
  
  /* spacement left	*/
  .pr-none-xlarge {
    padding-left: 0 !important;
  }
  
  .pl-xs-xlarge {
    padding-left: 5px !important;
  }
  
  .pl-sm-xlarge {
    padding-left: 10px !important;
  }
  
  .pl-md-xlarge {
    padding-left: 15px !important;
  }
  
  .pl-lg-xlarge {
    padding-left: 20px !important;
  }
  
  .pl-xl-xlarge {
    padding-left: 25px !important;
  }
  
  .pl-xlg-xlarge {
    padding-left: 30px !important;
  }
  
  /* spacement right	*/
  .pr-none-xlarge {
    padding-right: 0 !important;
  }
  
  .pr-xs-xlarge {
    padding-right: 5px !important;
  }
  
  .pr-sm-xlarge {
    padding-right: 10px !important;
  }
  
  .pr-md-xlarge {
    padding-right: 15px !important;
  }
  
  .pr-lg-xlarge {
    padding-right: 20px !important;
  }
  
  .pr-xl-xlarge {
    padding-right: 25px !important;
  }
  
  .pr-xlg-xlarge {
    padding-right: 30px !important;
  }
}

